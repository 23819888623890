<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
    :key="person.id"
  >
    <template v-slot:contents>
      <InputOptionLarge
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-distribution-tochildren.png'"
        :letter="'a'"
        :selected="person.distribution_backup_children"
        :option="
          hisHer(person.gender, true) + ' children (including future children)'
        "
        @selected="saveToChildren"
      >
        <template v-slot:text>
          <div v-html="toChildrenText"></div>
        </template>
      </InputOptionLarge>

      <InputOptionLarge
        v-if="otherPeople.length || otherGroups.length || otherCharities.length"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-distribution-otherbeneficiaries.png'"
        :letter="'b'"
        :selected="person.distribution_backup_other_beneficiaries"
        :option="'The other beneficiaries'"
        @selected="saveToOtherBeneficiairies"
      >
        <template v-slot:text>
          <div v-html="toOtherBeneficiariesText"></div>
        </template>
      </InputOptionLarge>
      <InputOptionLarge
        v-else
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-distribution-otherbeneficiaries.png'"
        :letter="'b'"
        :selected="person.distribution_backup_children_no"
        :option="'Name substitute beneficiaries'"
        @selected="saveToOthers"
      >
        <template v-slot:text>
          Pass your estate to other people/groups/charities, if
          {{ person.full_name }} passes away before you.
        </template>
      </InputOptionLarge>

      <InputOptionLarge
        v-if="otherPeople.length || otherGroups.length || otherCharities.length"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-distribution-newgroup.png'"
        :letter="'c'"
        :selected="
          person.distribution_backup_person || person.distribution_backup_group
        "
        :option="otherPersonGroupOption"
        @selected="show.addModal = true"
      >
        <template v-slot:text>
          <div>{{ otherPersonGroupText }}</div>
        </template>
      </InputOptionLarge>
      <PersonOrGroupModal
        v-show="show.addModal"
        :show="show.addModal"
        @close="closePersonGroupModal"
        @saveToPerson="saveToPerson"
        :edit="person"
        :peopleOptionList="peopleOptionList"
      ></PersonOrGroupModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionLarge from '@/common/ui/InputOptionLarge'
import PersonOrGroupModal from './Modals/PersonOrGroup'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { TextFormat } from '@/common/mixins/TextFormat'
import { http } from '@/services'

export default {
  name: 'DistributionPerson',
  mixins: [willStringHelpers, TextFormat],
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionLarge,
    PersonOrGroupModal
  },
  props: [],
  created() {
    var string = this.$route.params.name
    var id = this.$route.params.id
    this.personName = string.replace(/%20/g, ' ')
    this.personId = Number(id)
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch('updatePerson', this.person)
    var string = to.params.name
    var id = to.params.id
    this.personName = string.replace(/%20/g, ' ')
    this.personId = Number(id)
    next()
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('updatePerson', this.person)
    if (to.path === '/distribution/substitute_beneficiaries')
      this.saveToSubstitute()
    next()
  },
  computed: {
    person() {
      return this.$store.getters.people.filter(
        (person) =>
          String(person.full_name).toLowerCase() ===
            String(this.personName).toLowerCase() && person.id === this.personId
      )[0]
    },
    heading() {
      var string =
        'If ' +
        this.person.full_name +
        ' cannot inherit (because ' +
        this.heShe(this.person.gender) +
        ' has passed away before you)' +
        ', who would you like to benefit from ' +
        this.hisHer(this.person.gender) +
        ' ' +
        this.person.distribution_primary_percentage +
        '% share?'
      return string
    },
    number() {
      var number = 1
      if (this.partner) number += 1
      number += this.position + 1
      return number
    },
    partner() {
      return this.$store.getters.partner
    },
    people() {
      return this.$store.getters.people
    },
    peoplePrimary() {
      return this.$store.getters.peopleDistributionPrimary
    },
    groups() {
      return this.$store.getters.groups
    },
    groupsPrimary() {
      return this.$store.getters.groupsDistributionPrimary
    },
    charities() {
      return this.$store.getters.charities
    },
    otherPeople() {
      return this.peoplePrimary.filter((person) => person.id !== this.person.id)
    },
    otherGroups() {
      return this.groups.filter((group) => group.distribution_primary)
    },
    otherCharities() {
      return this.charities.filter((charity) => charity.distribution_primary)
    },
    peopleOptionList() {
      return this.$store.getters.people.filter(
        (person) => person.id !== this.person.id
      )
    },
    isValidated() {
      if (
        this.person &&
        (this.person.distribution_backup_person ||
          this.person.distribution_backup_group ||
          this.person.distribution_backup_children ||
          this.person.distribution_backup_other_beneficiaries ||
          this.person.distribution_backup_children_no)
      ) {
        return true
      }
      return false
    },
    position() {
      return this.peoplePrimary.findIndex(
        (person) => person.id === this.person.id
      )
    },
    forwardTo() {
      if (this.peoplePrimary && this.peoplePrimary.length > this.position + 1) {
        return `/distribution/primary_person/${
          this.peoplePrimary[this.position + 1].full_name
        }/${this.peoplePrimary[this.position + 1].id}`
      } else if (this.groupsPrimary && this.groupsPrimary.length) {
        return `/distribution/primary_group/${this.groupsPrimary[0].group_name}/${this.groupsPrimary[0].id}`
      } else if (
        !this.otherPeople.length &&
        !this.otherGroups.length &&
        !this.otherCharities.length &&
        this.person.distribution_backup_children_no
      ) {
        return '/distribution/substitute_beneficiaries'
      }

      if (this.otherCharities.length) {
       // reset distribution backup table
        this.$store.commit('details', {
          distribution_all_die_dont_care: false,
          distribution_all_die_backup_table: false
        })

        return '/distribution/minors'
      }

      return '/distribution/all_fail'
    },
    backTo() {
      if (this.peoplePrimary && this.position - 1 >= 0) {
        return `/distribution/primary_person/${
          this.peoplePrimary[this.position - 1].full_name
        }/${this.peoplePrimary[this.position - 1].id}`
      }
      return '/distribution/beneficiaries'
    },
    toChildrenText() {
      var string = ''
      if (
        this.otherPeople &&
        this.otherCharities &&
        this.otherGroups &&
        (this.otherPeople.length ||
          this.otherCharities.length ||
          this.otherGroups.length)
      ) {
        string +=
          'If ' +
          this.firstWord(this.person.full_name) +
          ' passes away before you ' +
          this.hisHer(this.person.gender) +
          ' share would be split ' +
          'between ' +
          this.hisHer(this.person.gender) +
          ' children (this includes any children born in the future). ' +
          'If when you pass away ' +
          this.heShe(this.person.gender) +
          ' does not have any children, the share would pass to the other beneficiaries (' +
          this.listPeopleGroupsCharities(
            this.otherPeople,
            this.otherGroups,
            this.otherCharities,
            ' and ',
            false
          ) +
          ')'
      } else {
        return (
          'If ' +
          this.firstWord(this.person.full_name) +
          ' passes away before you ' +
          this.hisHer(this.person.gender) +
          ' share would be ' +
          'split between ' +
          this.hisHer(this.person.gender) +
          ' children (this includes any children born in the future).'
        )
      }
      return string
    },
    toOtherBeneficiariesText() {
      return (
        'Selecting this option means that if ' +
        this.firstWord(this.person.full_name) +
        ' passes away before ' +
        'you, ' +
        this.hisHer(this.person.gender) +
        ' share will pass directly to the other beneficiaries ' +
        '(' +
        this.listPeopleGroupsCharities(
          this.otherPeople,
          this.otherGroups,
          this.otherCharities,
          ' and ',
          false
        ) +
        ', ' +
        'and not to ' +
        this.hisHer(this.person.gender) +
        ' children first).'
      )
    },
    otherPersonGroupOption() {
      if (this.person.distribution_backup_person) {
        return this.person.distribution_backup_person.full_name
      } else if (this.person.distribution_backup_group)
        return this.capitalise(this.person.distribution_backup_group)
      return 'Another person or group'
    },
    otherPersonGroupText() {
      if (
        this.otherPeople &&
        this.otherCharities &&
        this.otherGroups &&
        (this.otherPeople.length ||
          this.otherCharities.length ||
          this.otherGroups.length)
      ) {
        return (
          'Select this option if want ' +
          this.firstWord(this.person.full_name) +
          "'s share to pass to somebody else entirely in the event that " +
          this.heShe(this.person.gender) +
          ' passes away before you.'
        )
      } else {
        return (
          'Select this option if instead of ' +
          this.hisHer(this.person.gender) +
          ' children you would prefer for ' +
          this.firstWord(this.person.full_name) +
          "'s " +
          'share to pass to somebody else should ' +
          this.heShe(this.person.gender) +
          '  pass away before you.'
        )
      }
    }
  },
  data() {
    return {
      personName: null,
      personId: null,
      show: {
        intro: true,
        addModal: false
      },
      loading: false,
      subHeading:
        'When making a Will it is important to plan for every eventuality. Therefore you need to decide what ' +
        'happens to a share where the beneficiary has passed away either with, or before yourself.'
    }
  },
  methods: {
    saveToChildren() {
      var person = Object.assign({}, this.person)
      person.distribution_backup_children = true
      person.distribution_backup_other_beneficiaries = false
      person.distribution_backup_children_no = false
      person.distribution_backup_person = null
      person.distribution_backup_group = null
      this.$store.commit('personAdd', person)
    },
    saveToOtherBeneficiairies() {
      var person = Object.assign({}, this.person)
      person.distribution_backup_children = false
      person.distribution_backup_other_beneficiaries = true
      person.distribution_backup_children_no = false
      person.distribution_backup_person = null
      person.distribution_backup_group = null
      this.$store.commit('personAdd', person)
    },
    saveToOthers() {
      var person = Object.assign({}, this.person)
      person.distribution_backup_children = false
      person.distribution_backup_other_beneficiaries = false
      person.distribution_backup_children_no = true
      person.distribution_backup_person = null
      person.distribution_backup_group = null
      this.$store.commit('personAdd', person)
    },
    saveToPerson(personId, groupName) {
      var person = Object.assign({}, this.person)
      person.distribution_backup_children = false
      person.distribution_backup_other_beneficiaries = false
      person.distribution_backup_children_no = false
      person.distribution_backup_person = personId
      person.distribution_backup_group = groupName
      http
        .patch('/wills/api/person', person)
        .then((response) => {
          this.$store.commit('personAdd', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
      this.$store.commit('personAdd', person)
    },
    closePersonGroupModal() {
      this.show.addModal = false
    },
    saveToSubstitute() {
      this.$store.commit('details', {
        distribution_all_die_dont_care: false,
        distribution_all_die_backup_table: true
      })
    }
  }
}
</script>
