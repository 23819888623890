<template>
  <BaseModalSmall
    @close="close"
    v-show="show"
    :show="show"
    v-bind:isValidated="validateForm"
    v-bind:loading="showProgress"
    @confirm="save"
  >
    <template v-slot:header>{{ title }}</template>
    <template v-slot:body>
      <div class="row">
        <BeneficiaryAccordian
          v-bind:name="data.full_name"
          v-on:name="data.full_name = $event"
          v-bind:gender="data.gender"
          v-on:gender="data.gender = $event"
          v-bind:email="data.email"
          v-on:email="data.email = $event"
          v-bind:address="data.address"
          v-on:address="data.address = $event"
          v-bind:phone="data.phone"
          v-on:phone="data.phone = $event"
          v-bind:relationship="data.relationship"
          v-on:relationship="data.relationship = $event"
          v-bind:group="data.group"
          v-on:group="data.group = $event"
          v-bind:person="data.person"
          v-on:person="data.person = $event"
          v-bind:manualRelationship="manualRelationship"
          v-on:manualRelationship="manualRelationship = $event"
          :close="show"
          :errors="errors"
          :edit="edit"
          :gift="true"
          :shareNotRequired="true"
          :peopleOptionList="peopleOptionList"
          @showExisting="clearNew(), clearGroup()"
          @showNew="clearExsisting(), clearGroup()"
          @showGroup="clearExsisting(), clearNew()"
          @clearBeneficiaryError="errors.person = null"
          @clearFullNameError="errors.full_name = null"
          @clearEmailError="errors.email = null"
          @clearAddressError="errors.address = null"
          @clearPhoneError="errors.phone = null"
        >
        </BeneficiaryAccordian>
      </div>
    </template>
    <template v-slot:button>
      {{ buttonText }}
    </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'
import BeneficiaryAccordian from '@/views/pages/GiftsPage/Questions/Modals/BeneficiaryAccordian'
import { http } from '@/services'

export default {
  name: 'PersonOrGroupModal',
  components: {
    BaseModalSmall,
    BeneficiaryAccordian
  },
  props: ['show', 'edit', 'peopleOptionList'],
  watch: {
    edit: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          if (value.distribution_backup_group)
            this.data.group = value.distribution_backup_group
          else if (value.distribution_backup_person)
            this.data.person = value.distribution_backup_person.id
          else this.clearAll()
        }
      }
    }
  },
  computed: {
    validateForm() {
      if (this.data.person) return true
      if (this.data.full_name && this.data.gender && this.data.relationship) {
        if (
          this.data.relationship === 'friend' &&
          (this.data.phone || this.data.address || this.data.email)
        )
          return true
        else if (this.data.relationship === 'other' && this.manualRelationship)
          return true
        else if (
          this.data.relationship !== 'friend' &&
          this.data.relationship !== 'other'
        )
          return true
      }
      if (this.data.group) return true
      return false
    },
    title() {
      if (this.edit) return 'Change Person or Group'
      return 'Add Person or Group'
    },
    buttonText() {
      if (this.edit) return 'Update'
      return 'Add'
    }
  },
  data() {
    return {
      data: {
        person: '',
        group: '',
        full_name: null,
        address: null,
        email: null,
        phone: null,
        gender: null,
        relationship: ''
      },
      errors: {
        full_name: '',
        address: '',
        email: '',
        phone: ''
      },
      showProgress: false,
      manualRelationship: ''
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    clearErrors() {
      this.errors.full_name = ''
      this.errors.address = ''
      this.errors.phone = ''
      this.errors.email = ''
      this.errors.person = ''
    },
    clearAll() {
      this.clearExsisting()
      this.clearNew()
      this.clearGroup()
    },
    clearExsisting() {
      this.data.person = ''
    },
    clearNew() {
      this.data.full_name = null
      this.data.address = null
      this.data.email = null
      this.data.phone = null
      this.data.gender = null
      this.data.relationship = ''
    },
    clearGroup() {
      this.data.group = ''
    },
    save(value) {
      if (this.data.person || this.data.group) {
        this.patch()
      } else {
        this.post()
      }
    },
    post() {
      this.clearErrors()
      if (this.data.relationship === 'other' && this.manualRelationship)
        this.data.relationship = this.manualRelationship
      http
        .post('/wills/api/person', this.data)
        .then((response) => {
          this.$store.commit('personAdd', response.data)
          this.$emit('saveToPerson', response.data.id)
          this.close()
          this.$notification.success('Person Added')
        })
        .catch((error) => {
          this.errorHandler(error)
        })
    },
    patch() {
      this.$emit('saveToPerson', this.data.person, this.data.group)
      this.clearErrors()
      this.close()
    },
    errorHandler(e) {
      if (e.response.data.person) {
        this.errors.person = e.response.data.person[0]
      }
      if (e.response.data.group) {
        this.errors.group = e.response.data.group[0]
      }
      if (e.response.data.full_name) {
        this.errors.full_name = e.response.data.full_name[0]
      }
      if (e.response.data.phone) {
        this.errors.phone = e.reponse.data.phone[0]
      }
      if (e.response.data.email) {
        this.errors.email = e.reponse.data.email[0]
      }
      if (e.response.data.address) {
        this.errors.address = e.reponse.data.address[0]
      }
    }
  }
}
</script>
